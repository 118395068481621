import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import _ from 'lodash'

import Dashboard from '../../../pages/dashboard/Dashboard'
import DashboardHM from '../../../pages/dashboard/DashboardHM'
import ManageVehicles from '../../smart/menuComponentsContainer/ManageVehicles'
import Events from '../../dump/menuComponents/Events'
import ManageDriversPage from '../../../pages/drivers/ManageDriversPage'
import Reports from '../../dump/menuComponents/Reports'
import ManageAssetsGroups from '../../dump/menuComponents/ManageAssetsGroups'
import { isInternalUser } from '../../helpers/constants'

const HomeAccess = (props) => {
    const { user, location } = props;
    const userRole = user.roles[0];

    if (!user) {
        return (<div />)
    }
    if (['fleet_maintainer', 'installer'].indexOf(userRole) > -1) {
        return <ManageVehicles />
    }
    if (['storage_manager', 'storage_user'].indexOf(userRole) > -1) {
        return <Events />
    }
    if (['custom_user', 'custom_partner'].includes(userRole)) {
        const userPermissions = user.permissions
        // const permission = _.find(userPermissions.filter(e => e.perm_category_name !== 'Other'), { a_view: true });
        const permissions = userPermissions
            .filter(e => e.perm_category_name !== 'Other' && e.a_view === true)
            .map(({ perm_category_id }) => perm_category_id)

        // if (permission && permission.perm_category_id !== 1) {
        //     switch (permission.perm_category_id) {
        //         case 2:
        //             return <Events />
        //         case 3:
        //             return <Reports />
        //         case 4:
        //             return <ManageVehicles location={{ state: { tabKey: 1 } }} />
        //         case 5:
        //             return <ManageVehicles />
        //         case 6:
        //             return <ManageAssetsGroups />
        //         case 11:
        //             return <ManageDriversPage />
        //         case 15:
        //             return <ManageVehicles location={{ state: { tabKey: 2 } }} />
        //         default:
        //             browserHistory.push('/not-found')
        //             return;
        //     }
        // } else if (!permission) {
        if (permissions.length && !permissions.includes(1)) {
            if (permissions.includes(2)) {
                return <Events />
            }
            if (permissions.includes(3)) {
                return <Reports />
            }
            if (permissions.includes(4)) {
                return <ManageVehicles location={{ state: { tabKey: 1 } }} />
            }
            if (permissions.includes(5)) {
                return <ManageVehicles />
            }
            if (permissions.includes(6)) {
                return <ManageAssetsGroups />
            }
            if (permissions.includes(11)) {
                return <ManageDriversPage />
            }
            if (permissions.includes(15)) {
                return <ManageVehicles location={{ state: { tabKey: 2 } }} />
            }
            if (permissions.includes(16)) {
                return <ManageUsers />
            }
            if (permissions.includes(17)) {
                return <Firmware />
            }
            browserHistory.push('/not-found')
        }
        else if (!permissions.length) {
            browserHistory.push('/not-found')
            return;
        }
    }

    if (location && location.state) {
        return (!isInternalUser(userRole) ? <Dashboard location={location} /> : <DashboardHM location={location} />);
        // return <Dashboard location={location} />
    }

    return (!isInternalUser(userRole) ? <Dashboard /> : <DashboardHM />);
    // return <Dashboard />
}

export default connect(
    state => ({
        user: state.user.user,
    }),
)(HomeAccess);

import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import DateTime from 'react-datetime'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'

import momenttz from '../../../component/helpers/momenttz'

import './AvailableDatesNav.sass'

const AvailableDatesNav = (props) => {
	const { eventDate, availableDates, sizePerPage, getFleetEvents, getDeviceEventFilters, setPropStates, clearTimer, isTripHistory } = props
	const [openDateTime, setOpenDateTime] = useState(false) // FWSD-6893
	const dateTimeRef = useRef(null) // reference to DateTime component 

	// FWSD-6893, detects a click outside a DateTime component 
	useEffect(() => {
		const onOutsideClick = (e) => {
			if (!dateTimeRef?.current?.contains(e.target)) {
				// e.preventDefault();
				// e.stopPropagation();
				setOpenDateTime(false)
			}
		}

		// targeting on the user clicking on the out of DateTime component 
		document.addEventListener("mousedown", onOutsideClick, true)
		return () => {
			document.removeEventListener("mousedown", onOutsideClick, true)
		}
	}, [])

	// console.log('PROPS AvailableDatesNav:', props)
	const validDate = (current) => {
		return _.indexOf(availableDates, current.format('YYYY-MM-DD')) !== -1
	}

	const onDateChanged = (current) => {
		if (!moment(current, 'MM/DD/YYYY', true).isValid()) {
			toastr.error('', 'Please enter valid date value.')
			return
		}

		toastr.removeByType('error')
		setOpenDateTime(false)
		setPropStates({ eventDate: current }, () => clearTimer())
		// getDeviceEventFilters(current)
		getFleetEvents(1, sizePerPage, true, current)
	}

	const getPreviousEvents = () => {
		const pos = _.indexOf(availableDates, eventDate)
		// debugger
		if (pos === -1 && availableDates.length) {
			const arr = availableDates.filter((date) => {
				return (momenttz(date).format('YYYY-MM-DD') < momenttz(eventDate).format('YYYY-MM-DD'))
			})

			if (arr.length) {
				setPropStates({ eventDate: arr[arr.length - 1] }, () => clearTimer())
				// getDeviceEventFilters(arr[arr.length - 1])
				getFleetEvents(1, sizePerPage, true, arr[arr.length - 1])
			} else {
				toastr.info('', `No previous ${isTripHistory ? 'trips' : 'events'} available`)
			}
		} else if (pos > 0) {
			setPropStates({ eventDate: availableDates[pos - 1] }, () => clearTimer())
			// getDeviceEventFilters(availableDates[pos - 1])
			getFleetEvents(1, sizePerPage, true, availableDates[pos - 1])
		} else {
			toastr.info('', `No previous ${isTripHistory ? 'trips' : 'events'} available`)
		}
	}

	const getNextEvents = () => {
		const pos = _.indexOf(availableDates, eventDate)

		if (pos === -1 && availableDates.length) {
			const arr = availableDates.filter((date) => {
				return (momenttz(date).format('YYYY-MM-DD') > momenttz(eventDate).format('YYYY-MM-DD'))
			})

			if (arr.length) {
				setPropStates({ eventDate: arr[0] }, () => clearTimer())
				// getDeviceEventFilters(arr[0])
				getFleetEvents(1, sizePerPage, true, arr[0])
			} else {
				toastr.info('', `No future ${isTripHistory ? 'trips' : 'events'} available`);
			}
		} else if (pos > -1 && pos < (availableDates.length - 1)) {
			setPropStates({ eventDate: availableDates[pos + 1] }, () => clearTimer())
			// getDeviceEventFilters(availableDates[pos + 1])
			getFleetEvents(1, sizePerPage, true, availableDates[pos + 1])
		} else {
			toastr.info('', `No future ${isTripHistory ? 'trips' : 'events'} available`)
		}
	}

	return (
		<div className="AvailableDatesNav">
			<div className="arrows">
				<svg className="previous-date" onClick={getPreviousEvents} width='24px' height='24px' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"></path></svg>
			</div>
			<div ref={dateTimeRef} className="input event-date">
				<DateTime
					open={openDateTime}
					inputProps={{
						readOnly: true,
						onClick: () => setOpenDateTime(!openDateTime)
					}}
					value={moment(eventDate).format('MM/DD/YYYY')}
					dateFormat="MM/DD/YYYY"
					timeFormat={false}
					// closeOnSelect
					isValidDate={validDate}
					onChange={onDateChanged}
					// closeOnClickOutside
				/>
			</div>
			<div className="arrows">
				<svg className="next-date" onClick={getNextEvents} width='24px' height='24px' xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"><path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path></svg>
			</div>
		</div>
	)
}

AvailableDatesNav.propTypes = {
	eventDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(moment),
	]).isRequired,
	availableDates: PropTypes.arrayOf(PropTypes.string).isRequired,
	sizePerPage: PropTypes.number.isRequired,
	getFleetEvents: PropTypes.func.isRequired,
	// getDeviceEventFilters: PropTypes.func.isRequired,
	setPropStates: PropTypes.func.isRequired,
	clearTimer: PropTypes.func.isRequired
}

export default AvailableDatesNav;

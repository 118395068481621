import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import onResize from 'simple-element-resize-detector'

import moment from 'moment'
import momenttz from '../../../component/helpers/momenttz'
import MarkerAlertNode from '../TripsHistory/MarkerAlertNodeHM'
import TripSpeedEvents from '../TripsHistory/TripSpeedEventsHM'
import MarkerHistoryPopup from './MarkerHistoryPopupHM'

const SimpleMapExampleHEREMap = (props) => {
	const { userRole, liveVideoUserAccess, speed_unit, showLiveTraffic, showTripsHistory, onClickSnapRoad, onClickAddress, onSelectMarker, markers, polygons, tripsGPS, tripsGPSSnapRoad, tripsEvents, activeTreeItem, showSnapRoad, selectedTrip, center, zoom } = props;

	let mapRef = useRef(null);
	const [mapState, setMapState] = useState({});
	const [polyline, setPolyline] = useState(null);
	const [map, setMap] = useState(null);
	const [layer, setLayer] = useState(null);
	const [mapUI, setMapUI] = useState(null);

	useEffect(() => {
		if (!map) {
			// instantiate a platform, default layers and a map as usual
			const platform = new H.service.Platform({
				apikey: process.env.REACT_APP_HERE_MAPS_API_KEY
			});
			const layers = platform.createDefaultLayers();
			const MAP = new H.Map(
				mapRef,
				layers.vector.normal.map,
				{
					center,
					zoom,
					pixelRatio: window.devicePixelRatio || 1
				},
			);

			onResize(mapRef, (a, p, c) => {
				MAP.getViewPort().resize();
			});
			// attach the listener
			MAP.addEventListener('mapviewchange', handleMapViewChange);
			// add the interactive behaviour to the map
			new H.mapevents.Behavior(new H.mapevents.MapEvents(MAP));
			// Create the default UI components
			const ui = H.ui.UI.createDefault(MAP, layers);
			ui.getControl('mapsettings').setAlignment('top-left');
			ui.getControl('zoom').setAlignment('right-top');

			setMap(MAP);
			setMapUI(ui);
			props.setMap(MAP);

			MAP.setCenter(center);
			MAP.setZoom(zoom, true);
		}

		return () => {
			if (map) map.removeEventListener('mapviewchange', handleMapViewChange);
		}
	}, [])

	useEffect(() => {
		if (map) {
			if (map.getLayers().asArray().length <= 2) {
				map.setCenter(center);
				map.setZoom(zoom, true);

				const dataPoints = [];
				getMarkers(markers).map(marker => dataPoints.push(new H.clustering.DataPoint(marker.position.lat, marker.position.lng, null, marker)));
				// Create a clustered data provider and a theme implementation
				const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
					clusteringOptions: {
						// Maximum radius of the neighbourhood
						eps: 40,
						// minimum weight of points required to form a cluster
						minWeight: 3
					}
				});

				clusteredDataProvider.getTheme().getNoisePresentation = function (noisePoint) {
					const data = noisePoint.getData();
					// Create an icon, an object holding the latitude and longitude, and a marker:
					const noiseMarker = new H.map.DomMarker(noisePoint.getPosition(), {
						icon: createMarker(data),
						// Use min zoom from a noise point to show it correctly at certain zoom levels
						min: map.getZoom() >= 13 ? 0 : noisePoint.getMinZoom(),
						zIndex: data.zIndex,
					});
					// Bind noise point data to the marker:
					noiseMarker.setData(data);

					return noiseMarker;
				}
				// Create a layer that includes the data provider and its data points: 
				const layer = new H.map.layer.ObjectLayer(clusteredDataProvider);
				// Add the layer to the map:
				map.addLayer(layer);
				setLayer(layer);
			} else {
				const clusteredDataProvider = layer.getProvider();
				const dataPoints = [];
				getMarkers(markers).map(marker => { dataPoints.push(new H.clustering.DataPoint(marker.position.lat, marker.position.lng, null, marker)) });
				clusteredDataProvider.setDataPoints(dataPoints);

				if (dataPoints.length === 1 && selectedTrip) {
					const marker = getMarkers(markers)[0];
					clusteredDataProvider.getRootGroup().getObjects()[0]?.setGeometry({ lat: marker.position.lat, lng: marker.position.lng });
					clusteredDataProvider.getRootGroup().getObjects()[0]?.setIcon(createMarker(marker));
				}
			}
		}
	}, [props]);

	useEffect(() => {
		if (selectedTrip) {
			map && map.getObjects().length && map.removeObjects(map.getObjects());

			let polylines = (showSnapRoad && tripsGPSSnapRoad.length && tripsGPSSnapRoad) || (tripsGPS.length && tripsGPS) || polygons || [];
			const polylineOptions = {
				strokeColor: 'rgba(66, 104, 168, 1.0)',
				lineWidth: 5,
			};

			const dashedPolylineOptions = {
				strokeColor: 'rgba(255, 255, 255, 0.5)',
				lineWidth: 3,
				lineDash: [1, 7],
				lineHeadCap: "arrow-head",
				lineTailCap: "arrow-tail",
			};

			tripsGPS.length && tripsGPS.map((polygon) => {
				const lineString = new H.geo.LineString();
				if (polygon.polygon.length) {
					polygon.polygon.map(elm => {
						lineString.pushPoint({ lat: elm.lat, lng: elm.lng });
					});
					const polyline = new H.map.Polyline(lineString, { style: polylineOptions });
					const dashedPolyline = new H.map.Polyline(lineString, { style: dashedPolylineOptions });

					polyline.addEventListener("pointerenter", (e) => getTripTime(e, true), true);
					polyline.addEventListener("pointermove", (e) => getTripTime(e, true), true);
					polyline.addEventListener("pointerleave", (e) => setTimeout(() => getTripTime(e, false), 1000), true);
					map.addObject(polyline);
					map.addObject(dashedPolyline);
					setPolyline(polyline);

					if (!selectedTrip.isLiveTrip) {
						map.getViewModel().setLookAtData({
							bounds: polyline.getBoundingBox()
						});
					}
				}

				if (polylines.length > 0 && polylines[0].polygon.length > 0) {
					const divIcon = document.createElement('div');
					divIcon.setAttribute('class', 'map-narker');
					const imgStart = document.createElement('img');
					imgStart.setAttribute('class', 'markerImg');
					imgStart.setAttribute('src', '/images/ico/marker_start.svg');
					imgStart.setAttribute('title', "Start Trip");
					imgStart.style.zIndex = 10000;
					imgStart.style.opacity = 1;
					divIcon.appendChild(imgStart);
					map.addObject(new H.map.DomMarker({ lat: polylines[0].polygon[0].lat, lng: polylines[0].polygon[0].lng }, {
						icon: new H.map.DomIcon(divIcon)
					})
					);
				}
				// FWSD-4032
				// FOR LIVE TRIP ONLY: Remove the B on the map to indicate the end of the trip. Since the trip hasn’t stopped, the B should be removed.
				if (polylines.length > 0 && polylines[0].polygon.length > 0 && selectedTrip && !selectedTrip.isLiveTrip) {
					const divIcon = document.createElement('div');
					divIcon.setAttribute('class', 'map-narker');
					const imgStop = document.createElement('img');
					imgStop.setAttribute('class', 'markerImg');
					imgStop.setAttribute('src', '/images/ico/marker_stop.svg');
					imgStop.setAttribute('title', "Stop Trip");
					imgStop.style.zIndex = 10000;
					imgStop.style.opacity = 1;
					divIcon.appendChild(imgStop);
					map.addObject(new H.map.DomMarker({ lat: polylines[0].polygon[polylines[0].polygon.length - 1].lat, lng: polylines[0].polygon[polylines[0].polygon.length - 1].lng }, {
						icon: new H.map.DomIcon(divIcon)
					})
					);
				}
			});
		} else {
			if (map) {
				map.getObjects().length && map.removeObjects(map.getObjects());
				if (polyline) {
					polyline.removeEventListener("pointerenter", (e) => getTripTime(e, true), true);
					polyline.removeEventListener("pointermove", (e) => getTripTime(e, true), true);
					polyline.removeEventListener("pointerleave", (e) => setTimeout(() => getTripTime(e, false), 1000), true);
				}
			}
		}
	}, [tripsGPS, selectedTrip]);

	const getTripTime = (e, timeLoc) => {
		const renderElements = () => {
			const object = map.getObjects().filter(item => (item.type === H.map.Object.Type.DOM_MARKER && item.getRemoteId() === "circle"))[0];
			const elmBubble = mapUI.getBubbles().filter(bubble => bubble.getElement().className.includes("infoTripTime"));
			const bubble = elmBubble.length ? elmBubble[0] : null;

			return { object, bubble };
		}

		if (timeLoc) {
			const position = map.screenToGeo(e.currentPointer.viewportX, e.currentPointer.viewportY);
			const arr = props.tripsGPS[0].polygon;
			let minObj = null;
			let distance = null;

			arr.forEach((item) => {
				const { lat, lng } = item;
				const calcDistance = (lat1, lng1, lat2, lng2) => {
					const R = 6371e3; // metres
					const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
					const φ2 = lat2 * Math.PI / 180;
					const Δφ = (lat2 - lat1) * Math.PI / 180;
					const Δλ = (lng2 - lng1) * Math.PI / 180;

					const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
						Math.cos(φ1) * Math.cos(φ2) *
						Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
					const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
					const d = R * c; // in metres

					return d;
				}

				distance = calcDistance(lat, lng, position.lat, position.lng);
				// console.log('distance: ', distance)
				// calculates smallest distance between hovered point and each of points of poligon. The "smallest distance" polygon item contains needed timestamp. 
				if (minObj === null || distance < minObj.distance) {
					minObj = { ...item, distance }
				}
			});

			// const ts =  moment.utc(minObj.timestamp).format('hh:mm:ss a') || 'NO TIME
			const ts = moment(minObj.timestamp).format('hh:mm:ss a') || 'NO TIME'

			const object = renderElements().object;
			const bubble = renderElements().bubble;

			if (object) {
				object.setGeometry(position);
			} else {
				const divIcon = document.createElement('div'),
					img = document.createElement('img');

				divIcon.setAttribute('class', 'map-narker');
				img.setAttribute('src', '/images/circle.png');
				img.style.transform = 'translate(-50%, -50%)';
				img.style.width = '10px';
				img.style.height = '10px';
				divIcon.appendChild(img);

				const marker = new H.map.DomMarker(position, { icon: new H.map.DomIcon(divIcon, { anchor: new H.math.Point(0, 0) }) });
				marker.setRemoteId("circle");
				map.addObject(marker);
			}

			if (bubble) {
				bubble.setPosition(position);
			} else {
				const bubbleElm = new H.ui.InfoBubble(position, {
					content: `<div className="trip-time text-center">${ts}</div>`
				});
				bubbleElm.addClass("infoTripTime");
				mapUI.addBubble(bubbleElm);
			}
		} else {
			const object = renderElements().object;
			const bubble = renderElements().bubble;

			object && map.removeObject(object);
			bubble && mapUI.removeBubble(bubble);
		}
	}

	const getMarkers = (markers) => {
		let data = [];
		markers.map(marker => {
			const isActive = activeTreeItem && activeTreeItem.device_id === marker.device_id;
			let visible_icon = marker.visible_icon /* || true */
			if ((!marker.position.lat * 1 && !marker.position.lng * 1)) {
				visible_icon = false
			}

			// if (props.singleTrucks.length && !marker.entry.single_truck) {
			// 	return false
			// }

			//* Does not return marker and cluster with empty data
			if (!visible_icon) {
				return false
			}

			marker.position.lat = marker.position?.lat;
			marker.position.lng = marker.position?.lng;
			marker.img_url = '/images/vehicle_icon/' + marker.icon_name + '_' + marker.icon_direction + (isActive ? '_active' : '') + '.png';
			marker.zIndex = isActive ? 10001 : 10000;

			data.push(marker);
		})
		return data;
	}

	globalThis.g_selectMarker = (device_id) => {
		onSelectMarker(device_id)
	}

	const createMarker = (data) => {
		const html = document.createElement('div'),
			label = document.createElement('div'),
			div = document.createElement('div'),
			divIcon = document.createElement('div'),
			img = document.createElement('img'),
			text = document.createElement('span');

		text.innerHTML = data.name;
		text.style.whiteSpace = "nowrap";
		divIcon.setAttribute('class', 'map-narker');
		label.setAttribute('class', 'map-price-container');
		div.setAttribute('class', `map-price-marker ${data.entry.status === 'parked' ? 'parking' : data.entry.status === 'driving' ? '' : 'offline'}`);
		img.setAttribute('src', data.img_url);

		div.appendChild(text);
		label.appendChild(div);
		divIcon.appendChild(img);
		html.appendChild(divIcon);
		html.appendChild(label);

		return (new H.map.DomIcon(html, {
			anchor: new H.math.Point(10, 10),
			onAttach: function (clonedElement, domIcon, domMarker) {
				clonedElement.addEventListener('click', () => g_selectMarker(data.device_id));
			},
			onDetach: function (clonedElement, domIcon, domMarker) {
				clonedElement.removeEventListener('click', () => g_selectMarker(data.device_id));
			},
		}));
	}

	const handleMapViewChange = (e) => {
		if (e.newValue && e.newValue.lookAt) {
			const lookAt = e.newValue.lookAt;
			// adjust precision
			const lat = Math.trunc(lookAt.position.lat * 1E7) / 1E7;
			const lng = Math.trunc(lookAt.position.lng * 1E7) / 1E7;
			const zoom = Math.trunc(lookAt.zoom * 1E2) / 1E2;

			setMapState({ lat, lng, zoom });
		}
	}

	return (
		<div
			id="map"
			style={{ position: 'relative', width: '100%', height: 'calc(100vh - 67px)' }}
			ref={(c) => { mapRef = c }}
		>
			<TripSpeedEvents device_id={activeTreeItem && activeTreeItem.device_id} map={map} mapUI={mapUI} />
			{(showTripsHistory && markers[0]) && (
				<MarkerHistoryPopup
					onClickAddress={onClickAddress}
					speed_unit={speed_unit}
					marker={markers[0]}
					mapUI={mapUI}
				/>
			)}
			{tripsEvents.length > 0 && (
				tripsEvents.map((item) => (
					<MarkerAlertNode
						key={item.id}
						eventId={item.id}
						eventType={item.event_type}
						position={item.position}
						timestamp={item.timestamp}
						map={map}
						mapUI={mapUI}
						polyline={polyline}
					/>
				))
			)}
		</div>
	)
}

const mapStateToProps = ({ dashboardData, user }) => ({
	selectedTrip: dashboardData.selectedTrip,
	tripsGPS: dashboardData.tripsGPS,
	tripsEvents: dashboardData.tripsEvents,
	tripAlertsMarkers: dashboardData.tripAlertsMarkers,
	tripsGPSSnapRoad: dashboardData.tripsGPSSnapRoad,
	showSnapRoad: dashboardData.showSnapRoad,
	showTripsHistory: dashboardData.showTripsHistory,
});

export default connect(mapStateToProps)(SimpleMapExampleHEREMap);
